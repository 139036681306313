import React, { useEffect, useRef } from 'react'

const Final = ({fullScreen, iframeRef}) => {
  let styles;
  useEffect(() => {
    if (fullScreen){
      styles = {
        height: '100vh',
      }
    }else{
      styles = {
        height: 'calc(100vh - 60px)'
      }
    }
  }, [fullScreen])
  
  return (
    <div>
      <iframe ref={iframeRef} style={styles} className='w-full calc' id='iframe_id' src=''></iframe>
    </div>
  )
}

export default Final  