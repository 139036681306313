import React from 'react'
import Header from '../components/Header'
import { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/Config';
const getData = () => {
  const datas = window.localStorage.getItem('data');
  if (datas !== null){
    return JSON.parse(datas);
  }else{
    return null;
  }
}
const SignUp = () => {

  const [data, setData] = useState(getData());
  const [username, setUsername] = useState(' ');
  const [fullName, setFullName] = useState(' ');
  const [password, setPassword] = useState(' ');
  const [repeatPassword, setRepeatPassword] = useState(' ');
  const [error, setError] = useState(' ');
  const [users, setUsers] = useState([]);
  const handleSubmit = async (e) => {
    try {
      await addDoc(collection(db, 'users'), {
        username: username,
        fullName: fullName,
        password: password,
        codeplaces: [
          {
            name: 'First'
          }
        ]
      }).then((docRef) => {
        console.log('added');
        window.location.href = "/";  
      })
    } catch (err) {
      alert(err)
    }
  }

  useEffect(() => {
    window.localStorage.setItem('data', JSON.stringify(data));
  }, [data])

  const signUp = (e) => {
    e.preventDefault();
    setError(' ');
    let da = true;
    if (username === ' ' || fullName === ' ' || password === ' ' || repeatPassword === ' '){
      setError('You need to complete the entire form')
      return;
    }
    else if (password.length < 8){ setError('The password length needs to be greater than 8'); return ;}
    else if (password !== repeatPassword){ setError('The password need to be same as repeat password'); return;}
    
    const q = query(collection(db, 'users'), where("username", "==", username))
    onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map(doc => ({
        username: doc.get('username'),
      })))
    })
    if (users.length !== 0){
      e.setError('This username is taken.')
    }
    setData({
      username: username,
      fullName: fullName,
      password: password,
    })
    handleSubmit();
  }

  return (
    <div>
      <>
      <Header />
      </>
      <section className="flex justify-center items-center w-full h-[] bg-gradient-to-r from-blue-900 to-violet-900 login">
      <div className='flex justify-center items-center'>
        <div className="rounded-[8px] py-3 px-10 text-white bg-opacity-50 bg-headingColor flex justify-center items-center flex-col gap-7">
          <p className='text-white font-[800] text-[16px] sm:text-[30px]'>Sign Up</p>
          <div className='flex justify-center items-center flex-col gap-5 my-3'>
            {error !== ' ' && (<p className='text-red-600 font-[600]'>{error}</p>)}
            <form autoComplete='off' action="" className='flex justify-center items-center flex-col gap-5' onSubmit={(e) => {signUp(e)}}>
            <div className="flex gap-4 flex-col sm:flex-row">
              <input type="text" placeholder='username' onChange={(e) => setUsername(e.target.value)} className='text-white bg-smallTextColor rounded-[3px] px-7 h-11' />
              <input type="text" placeholder='full name' onChange={(e) => setFullName(e.target.value)} className='text-white bg-smallTextColor rounded-[3px] px-7 h-11'/>
            </div>
            <div className="flex gap-4 flex-col sm:flex-row">
            <input type="password" placeholder='password' onChange={(e) => setPassword(e.target.value)} className='text-white bg-smallTextColor rounded-[3px] px-7 h-11' />
            <input type="password" placeholder='repeat password' onChange={(e) => {setRepeatPassword(e.target.value)}} className='text-white bg-smallTextColor rounded-[3px] px-7 h-11'/>
            </div>
            <button type='submit' className='text-white bg-smallTextColor rounded-[4px] px-7 h-11 hover:bg-headingColor font-[800] text-[16px] ease-in duration-500'>Sign Up</button>
            </form>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export {getData}

export default SignUp
