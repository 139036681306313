import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

const CodeSpace = ({id, codespace, setShowModal, setShowCodeSettings, setSettingsCodePlace, codePlaces}) => {
  let _id = '';
  if (codespace != null){
    _id = codespace['name'];
  }
  const navigate = useNavigate();
  const idToString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idToString(_id);
  const handleDetails = () => {
    navigate(`/code/${rootId}`, {
      state: {
        code: codespace,
        id: id,
        codePlaces: codePlaces,
      },
    })
  }
  return (
    <div>
      {codespace !== null ? (
        <div onClick={() => handleDetails()} className='m-auto relative mt-5 flex cursor-pointer bg-white justify-center items-center w-[300px] h-[150px] bg-gradient-to-r from-purple-600 to-violet-900 border-solid border-headingColor border-[3px] rounded-[10px] group'>
          <p className='text-white font-[800] text-[24px] group-hover:text-black'>{codespace['name']}</p>
          <button onClick={(e) => {e.stopPropagation(); setShowCodeSettings(true); setSettingsCodePlace(codespace)}} className='absolute top-2 right-3 text-white'><i className="ri-settings-3-line"></i></button>
      </div>
      ) : (
        <div onClick={() => {setShowModal(true);}} className='m-auto flex justify-center items-center text-[40px] text-white mt-5 bg-white w-[300px] h-[150px] bg-gradient-to-r from-purple-600 to-violet-900 border-solid border-headingColor border-[3px] rounded-[10px] group'>
        <i className="ri-add-fill"></i>
      </div>
      )}
    </div>
  )
}

export default CodeSpace
