import { Switch } from '@mui/material'
import React from 'react'

const ImgModal = ({setImgLink ,setImgChecked, checked, setImgModal}) => {
  const handleSwitch = () => {
    if (checked) {
      setImgChecked(false);
    }else{
      setImgChecked(true);
    }
  }
  return (
    <div className='fixed top-0 left-0 z-10 w-full h-full bg-headingColor bg-opacity-40'>
      <div className="max-w-[600px] flex flex-col gap-4 justify-center absolute top-1/2 left-1/2 z-20 bg-gray-800 rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
        <p className="text-white">Images</p>
        <div className='flex text-white'>
          Enabled
          <Switch checked={checked} onChange={() => {handleSwitch()}}/>
          <button onClick={(e) => {setImgModal(false); }} className='absolute text-white top-3 right-5'><i className="ri-close-fill"></i></button>
        </div>
      </div>
      
    </div>
  )
}

export default ImgModal
