import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../config/Config'

const Modal = ({setShowModal, id, codeplaces}) => {

  const [name, setName] = useState(' ');
  const [error, setError] = useState(' ');
  const checkName = () => {
    let i = 0;
    codeplaces.forEach((item) => {
      if (item.name === name){
        i += 1;
      }
    })
    if (i === 0){
      return true;
    }else{
      return false;
    }
  }
  const createNewCodeSpace = async () => {
    if (checkName()){
      const userRef = doc(db, 'users', id);
      const sd = codeplaces.concat([{name: name}])
      await updateDoc(userRef, {
        codeplaces: sd,
      });
      setShowModal(false);
      window.location.href = '/'
    }else{
      setError("This name is taken");
    }
  }

  return (
    <div className='w-full h-full fixed top-0 left-0 z-10 bg-headingColor bg-opacity-40'>
      <div className="max-w-[600px] flex flex-col gap-4 justify-center absolute top-1/2 left-1/2 z-20 bg-gray-800 rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
        <p className="text-white">Create e new Codespace</p>
        <p className="text-red-600">{error}</p>
        <input type="text" placeholder='Name:' onChange={(e) =>setName(e.target.value) } className='rounded-[8px] px-3 py-1 bg-gray-700 placeholder:text-white' />
        <button onClick={() => createNewCodeSpace()} className="text-white rounded-[8px] boder-solid border-black bg-gray-500 hover:bg-gray-800 ease-in-out duration-500">Create</button>
        <button onClick={() => setShowModal(false)} className='absolute top-3 right-5 text-white'><i className="ri-close-fill"></i></button>
      </div>
      
    </div>
  )
}

export default Modal
