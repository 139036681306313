import Ambiance from "./Ambiance.png";
import Chrome from "./Chrome.png";
import Clouds from "./Clouds.png";
import Cobalt from "./Cobalt.png";
import Dracula from "./Dracula.png";
import Sepiarize from "./Sepiarize.png";

const themes  = [
  {
    name: 'ambiance',
    src: Ambiance,
  },
  {
    name: 'chrome',
    src: Chrome,
  },
  {
    name: 'clouds',
    src: Clouds,
  },
  {
    name: 'cobalt',
    src: Cobalt,
  },
  {
    name: 'dracula',
    src: Dracula,
  },
  {
    name: 'dawn',
    src: Sepiarize,
  }
]

export default themes;