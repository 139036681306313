import React from 'react'

const SupportMe = () => {
  return (
    <div id='support' className='flex flex-col items-center justify-center h-[100vh]'>
      <p className='text-white text-[40px] sm:text-[60px] font-[Pacifico]'>Support the Developer</p>
      <div className='flex justify-center text-center items-center flex-col gap-3 my-3 text-[10px] sm:text-[15px] lg:text-[35px] list-disc text-white font-[Exo]'>
      If you want to help the developer to develop thinks like this and if you want to 
maintain this service and others, please consider donating on <a href="https://paypal.me/xSenny?country.x=MD&locale.x=en_US "><i class="ri-paypal-line"></i></a>
      </div>
      <div className='flex text-center justify-center items-center flex-col gap-3 my-3 text-[10px] sm:text-[15px] lg:text-[35px] list-disc text-white font-[Exo]'>
      Thanks you for all support!
      </div>
    </div>
  )
}

export default SupportMe
