import React from 'react'
import { Link } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";



const LandingHeader = () => {

  const getData = () => {
  const datas = window.localStorage.getItem('data');
  if (datas !== null){
    return JSON.parse(datas);
  }else{
    return null;
  }
}

  const LogInButton = () => {
    if (getData() == null){
      return "/login"
    }else{
      return "/home"
    }
  }
  return (
    <div className='bg-[#3F0D71] h-[70px] w-full flex items-center justify-around'>
      <div className='bg-[#181616] rounded-[13px] flex justify-center items-center w-[124px] h-[41px] text-[#0E2B76] text-[20px] font-[Ubuntu]'>XCompiler</div>
      <a href="#home" className='hidden sm:block decoration-none text-[#919191] font-[Roboto]'><p>Home</p></a>
      <a href='#features' className='hidden sm:block decoration-none text-[#919191] font-[Roboto]'><p>Features</p></a>
      <a href='#about' className='hidden sm:block decoration-none text-[#919191] font-[Roboto]'><p>About Developer</p></a>
      <a href='#support' className='hidden sm:block decoration-none text-[#919191] font-[Roboto]'><p>Support Developer</p></a>
      <Link className='bg-[#132A64] px-7 py-3 font-[Roboto] text-white rounded-[12px]' to={LogInButton()}>Log In</Link>
    </div>
  )
}

export default LandingHeader
