import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getData } from '../pages/SignUp';

const Header = () => {
  const [data, setData] = useState(getData());
  return (
    <header>
      <div className='flex justify-around items-center h-[60px] bg-headingColor'>
        <Link to='/home'>
          <div className="rounded-full bg-black text-white p-2 flex justify-center items-center  text-[10px] sm:text-[18px] font-[600]">XCompiler</div>
        </Link>
        {data === null ? (
          <div className='flex gap-5 items-center'>
            <Link to="/signup"><button className='bg-primaryColor text-white font-[600] py-1 px-3 rounded-[4px] text-[10px] sm:text-[16px]'>Sign Up</button></Link>
            <Link to="/login"><button className='bg-primaryColor text-white font-[600] py-1 px-3 rounded-[4px] text-[10px] sm:text-[16px]'>Log In</button></Link>
          </div>
        ) : (
          <>
          <div className="rounded-full px-3 py-2 text-white bg-black flex justify-center items-center">{data.username.charAt(0).toUpperCase()}</div>
          <button onClick={() => {setData(null); window.location.href = "/"; window.localStorage.removeItem('data')}} className='bg-red-600 text-white rounded-[8px] px-3 py-1'>Log out</button>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
