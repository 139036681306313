import React, { useEffect, useState } from 'react'
import Header from './../components/Header';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/Config';
import { getData } from './SignUp';
import loading from '../assets/loading.webp'

const LoginMe = () => {
  const [username, setUsername] = useState(' ');
  const [password, setPassword] = useState(' ');
  const [error, setError] = useState(' ');
  const [users, setUsers] = useState([]);
  const [data, setData] = useState(getData());
  const [rightUser, setRightUser] = useState({password: '1'});
  const [show, setShow] = useState(false)
  useEffect(() => {
    window.localStorage.setItem('data', JSON.stringify(data));
  }, [data])
  const fetchData = async () => {
    const q = await getDocs(collection(db, 'users'));
    const items = [];
    q.forEach((doc) => {
      items.push({
        username: doc.get('username'),
        password: doc.get('password'),
      });
    });
    setUsers(items);
  };
  useEffect(() => {
    fetchData();
  }, [])
  useEffect(() => {
    if (rightUser.password === '1'){
      setError(' ')
    }
    else if (rightUser.password === ' '){
      setError('This username is not signed in, use Sign in to sign in.')
    }else if (rightUser !== null && rightUser.password !== password){
      setError('Wrong password')
    }else if (rightUser !== null && rightUser.password === password){
      setData({
        username: username,
        password: password,
      })
      window.location.href = '/'
    }else{
      setError('pula')
    }
  }, [rightUser])
  const login = (e) => {
    setRightUser({
      password: ' ',
    });
    e.preventDefault();
    setError(' ');
    users.forEach((user) => {
      if (user.username === username){
        setRightUser({
          username: user.username,
          password: user.password,
        })
      }
    })
    if (rightUser === null){
      setRightUser({
        password: ' ',
      })
    }
  }

  return (
    <>
    <Header />
    <section className="flex justify-center items-center w-full h-[] bg-gradient-to-r from-blue-900 to-violet-900 login">
      <div className='flex justify-center items-center'>
        <div className="rounded-[8px] py-3 px-10 text-white bg-opacity-50 bg-headingColor flex justify-center items-center flex-col gap-7">
          <p className='text-white font-[800] text-[16px] sm:text-[30px]'>Login</p>
          {show&&<img src={loading} className='h-[30px] w-[30px]' alt="" />}
          <div className='flex justify-center items-center flex-col gap-5 my-3'>
            {error !== ' ' && (<p className='text-red-600'>{error}</p>)}
            <form onSubmit={e => login(e)} action="" className='flex justify-center items-center flex-col gap-5'>
            <input type="text" placeholder='username' onChange={(e) => setUsername(e.target.value)} className='text-white bg-smallTextColor rounded-[3px] px-7 h-11' />
            <input type="password" placeholder='password' onChange={(e) => setPassword(e.target.value)} className='text-white bg-smallTextColor rounded-[3px] px-7 h-11'/>
            <button type='submit' className='text-white bg-smallTextColor rounded-[4px] px-7 h-11 hover:bg-headingColor font-[800] text-[16px] ease-in duration-500'>Login</button>
            </form>
            
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default LoginMe
