import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import firebase from'firebase/compat/app';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4W0XjXFeoKG6_pMwHrqMQ9YrZ1UdhULc",
  authDomain: "xcompiler-e2b2b.firebaseapp.com",
  projectId: "xcompiler-e2b2b",
  storageBucket: "xcompiler-e2b2b.appspot.com",
  messagingSenderId: "483010020798",
  appId: "1:483010020798:web:52df34b5d3808f2176e5ef",
  measurementId: "G-X2K9ECMCR5"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export {db};