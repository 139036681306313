import React from 'react'
import Com from '../assets/themes/com.png'
import { Link } from 'react-router-dom'
import Demo from '../pages/Demo'

const Home = () => {

  const getData = () => {
    const datas = window.localStorage.getItem('data');
    if (datas !== null){
      return JSON.parse(datas);
    }else{
      return null;
    }
  }
  const LogInButton = () => {
    if (getData() == null){
      return "/signup"
    }else{
      return "/home"
    }
  }
  return (
    <div id='home' className='flex-col sm:flex-row h-[200vh] sm:h-[100vh] flex align-center py-4'>
      <div className='flex flex-col items-center w-full sm:w-2/5 py-[200px] gap-5 sm:gap-14'>
        <p className='font-[Pacifico] text-white text-[60px]'>XCompiler</p>
        <p className='text-center text-white w-[220px] font-[Exo]'>A revolutionary Html/Css/Js
 Web Compiler</p>
      <div className='flex flex-row items-center gap-10'>
      <Link to={LogInButton()} className='bg-[#132A64] px-7 py-3 font-[Roboto] text-white rounded-[12px]'>Start now</Link>
      <Link className='text-white font-[Roboto] decoration-black' to='/demo'>Try Demo</Link>
      </div>
      </div>
      
      <div className='flex justify-center align-center'>
        <img className='w-3/4 sm:w-full' src={Com} alt="sa" />
      </div>
    </div>
  )
}

export default Home
