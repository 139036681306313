import { Switch } from '@mui/material';
import React, { useState } from 'react'

const ResolutionModal = ({setSeeResModal, styles, setThemeModal, setImgModal}) => {
  const [height, setHeight] = useState('10');
  const [width, setWidth] = useState('10');

  const createStyles = () => {
    styles( {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      width: width + 'px',
      height: height + 'px',
    })
    console.log(styles.width)
    console.log(styles.height);
  }

  return (
    <div className='fixed top-0 left-0 z-10 w-full h-full bg-headingColor bg-opacity-40'>
      <div className="max-w-[600px] flex flex-col gap-4 justify-center absolute top-1/2 left-1/2 z-20 bg-gray-800 rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
        <p className="text-white">Type your resolution in px:</p>
        <p className="text-red-600"></p>
        <input type="text" onChange={(e) => setWidth(e.target.value)} placeholder='Width:' className='rounded-[8px] px-3 py-1 bg-gray-700 placeholder:text-white' />
        <button onClick={() => {createStyles(); setSeeResModal(false); }}  className="text-white rounded-[8px] boder-solid border-black bg-green-500 hover:bg-green-800 ease-in-out duration-500">Set</button>
        <button onClick={(e) => {setSeeResModal(false); setThemeModal(true)}} className='text-white rounded-[8px] boder-solid border-black bg-red-500 hover:bg-red-800 ease-in-out duration-500'>Change theme</button>
        <button onClick={(e) => {setImgModal(true); setSeeResModal(false)}} className='text-white rounded-[8px] boder-solid border-black bg-blue-500 hover:bg-blue-800 ease-in-out duration-500'>Set an image</button>
        <button onClick={(e) => {setSeeResModal(false); }} className='absolute text-white top-3 right-5'><i className="ri-close-fill"></i></button>
      </div>
      
    </div>
  )
}

export default ResolutionModal
