import React, {  useEffect, useState } from 'react'
import Header from '../components/Header'
import { getData } from './SignUp'
import CodeSpace from '../components/CodeSpace';
import { Link } from 'react-router-dom';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/Config';
import Modal from '../components/Modal';
import CodeSpaceModal from '../components/CodeSpaceModal';

const Main = () => {
  const [data, setData] = useState(getData);
  const [codePlaces, setCodePlaces] = useState([]);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(0);
  const [showCodeSettings, setShowCodeSettings] = useState(false);
  const [settingsCodePlace, setSettingsCodePlace] = useState(null);
   

  useEffect(() => {
    setData(JSON.parse(window.localStorage.getItem('data')));
  }, [])

  const fetchData = async () => {
    const q = await getDocs(collection(db, 'users'));
    const items = [];
    q.forEach((doc) => {
      if (doc.get('username') === data.username){
        setCodePlaces(doc.get('codeplaces'));
        setId(doc.id);
      }
    });
    setUsers(items);
  };
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <div>
      <Header />
      <div className='flex items-center justify-center'>
      {
        data !== null ?(<div className='grid items-center justify-center w-full h-auto min-h-screen grid-cols-1 gap-10 p-5 sm:grid-cols-2 lg:grid-cols-3 bg-gradient-to-r from-blue-900 to-violet-900'>
          {codePlaces.map((code, index) => (
        <CodeSpace id={id} setShowCodeSettings={setShowCodeSettings} setSettingsCodePlace={setSettingsCodePlace} codespace={code} key={index} setShowModal={setShowModal}></CodeSpace>
      ))}
          <CodeSpace codespace={null} key={0} setShowModal={setShowModal}/>
        </div>)
        
        : (<div className='flex flex-col items-center justify-center w-full h-auto min-h-screen gap-10 bg-gradient-to-r from-blue-900 to-violet-900'>
          <p className='text-white sm:text-[32px] p-5 self-center sm:font-[800]'>You are not logged in, log in by clicking on the buttons below:</p>
          <div className='flex items-center gap-5'>
            <Link to="/signup"><button className='bg-primaryColor text-white font-[600] py-1 px-3 rounded-[4px] text-[10px] sm:text-[16px]'>Sign Up</button></Link>
            <Link to="/login"><button className='bg-primaryColor text-white font-[600] py-1 px-3 rounded-[4px] text-[10px] sm:text-[16px]'>Log In</button></Link>
          </div>
        </div>)
      }
      
      </div>
      {showModal && (<Modal  setShowModal={setShowModal} codeplaces={codePlaces} id={id} />)}
      {showCodeSettings && (<CodeSpaceModal codePlaces={codePlaces} id={id} setShowCodeSettings ={setShowCodeSettings} settingsCodePlace={settingsCodePlace} />)}
      </div>
  )
}

export default Main