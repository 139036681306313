import React from 'react'

const Features = () => {
  return (
    <div id='features' className='flex flex-col items-center justify-center h-[100vh]'>
      <p className='text-white text-[40px] sm:text-[60px] font-[Pacifico]'>Features</p>
      <ul className='flex justify-center items-center flex-col gap-3 my-3 text-[10px] sm:text-[15px] lg:text-[35px] list-disc text-white font-[Exo]'>
        <li>Create an account and store all your codespaces</li>
        <li>Create unlimited codespaces and rename them</li>
        <li>Create websites using Html, Css and Javascript</li>
        <li>Create an account only with an unique username and a password</li>
        <li>Use different themes for our code editor</li>
      </ul>
    </div>
  )
}

export default Features
