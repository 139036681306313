import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import './App.css';
import LoginMe from './pages/LoginMe';
import SignUp from './pages/SignUp';
import Main from './pages/Main';
import Product from './pages/Product';
import Test from './pages/Test';
import Demo from './pages/Demo';
import Landing from './pages/Landing';
import Live from './pages/Live';
import Home from './components/Home';

function App() {
  return (
    
      <Router>
        <Switch>
        <Route exact path="/home" element={<Main />}/>
          <Route path='/login' element={<LoginMe />}/>
          <Route path='/signup' element={<SignUp />}/>
          <Route path='/code/:id' element={<Product />} />
          <Route path='test' element={<Test />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='/' element={<Landing />} />
          <Route path='/live/:id' element={<Live />} />
        </Switch>
      </Router>
  )
}

export default App;
