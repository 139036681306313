import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../config/Config';

const CodeSpaceModal = ({setShowCodeSettings, settingsCodePlace, codePlaces, id}) => {

  const removeCodePlace = async () => {
    const index = codePlaces.indexOf(settingsCodePlace);
    codePlaces.splice(index, 1);
    console.log(codePlaces);
    const userRef = doc(db, 'users', id);
    await updateDoc(userRef, {
      codeplaces: codePlaces,
    });
    window.location.href = '/'
  }

  
  const [names, setNames] = useState([]);
  const [name, setName] = useState('name');
  const [error, setError] = useState(' ');
  const [sinput, setsInput] = useState(false);
  const [toWho, setToWho] = useState(' ');
  const firstName = settingsCodePlace.name;
  const checkName = () => {
    let i = 0;
    codePlaces.forEach((item) => {
      if (item.name === name && name !== firstName){
        i += 1;
      }
    })
    if (i === 0){
      return true;
    }else{
      return false;
    }
  }

  const fetchData = async () => {
    const q = await getDocs(collection(db, 'users'));
    const items = [];
    q.forEach((doc) => {
      items.push(doc.get('username'));
    });
    setNames(items);
  };
  useEffect(() => {
    fetchData();
  }, [])

  const contains = () => {
    names.forEach(name1 => {
      if (name1 === toWho){
        return true;
      }
    })
    return false;
  }

  const update = async () => {
    const userRef = doc(db, 'users', id);
    await updateDoc(userRef, {
      codeplaces: codePlaces,
    });
    window.location.href = '/'
  }

  const editName = () => {
    if (!checkName()){
      setError('This name is taken')
    }else{
      codePlaces.forEach((item) => {
        if (item.name === firstName){
          item.name = name;
          setShowCodeSettings(false);
          update();
          return;
        }
      })
    }
  }

  const sendTo = () => {
    
  }

  return (
    <div>
      <div className='w-full h-full fixed top-0 left-0 z-10 bg-headingColor bg-opacity-40'>
      <div className="max-w-[600px] flex flex-col gap-4 justify-center absolute top-1/2 left-1/2 z-20 bg-gray-800 rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
        <p className="text-white">Edit {settingsCodePlace.name}:</p>
        <p className='text-red-600'>{error}</p>
        <input type="text" onChange={(e) => setName(e.target.value)} placeholder='rename:' className='rounded-[8px] px-3 py-1 bg-gray-700 placeholder:text-white' />
        <button onClick={() => removeCodePlace()} className="text-white rounded-[8px] boder-solid border-black bg-red-500 hover:bg-red-800 ease-in-out duration-500">Delete <i class="ri-delete-bin-line"></i></button>
        <button onClick={() => editName()} className="text-white rounded-[8px] boder-solid border-black bg-gray-500 hover:bg-gray-700 ease-in-out duration-500">Save <i class="ri-save-2-line"></i></button>
        <button onClick={(e) => {
          if (!sinput) setsInput(true);
          else{
            if (!contains){
              setError('acest nume nu exista')
            }
          }
        }} className='text-white rounded-[8px] border-solid border-black bg-blue-600 hover:bg-blue-800 ease-in-out duration-500'>Share <i class="ri-stackshare-line"></i></button>
        <button onClick={() => {setShowCodeSettings(false)}} className='absolute top-3 right-5 text-white'><i className="ri-close-fill"></i></button>
        {sinput &&
        <input type="text" onChange={(e) => setToWho(e.target.value)} placeholder='username:' className='rounded-[8px] px-3 py-1 bg-gray-700 placeholder:text-white' />}
      </div>
      
    </div>
    </div>
  )
}

export default CodeSpaceModal
