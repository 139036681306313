import React from "react";
import { render } from "react-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-dawn"
import "ace-builds/src-noconflict/mode-html"
import Image from "../components/Image";
const Test = () => {
  return (

  <Image />
  );


}

export default Test
