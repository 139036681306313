import React from 'react'
import { useState } from 'react';

import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'

const Image = ({link}) => {

  return (
    <div>
      <Draggable>
        <img className='z-[1000] h-[400px] w-[600px]' src={link} alt="" />
      </Draggable>
    </div>
  )
}

export default Image
