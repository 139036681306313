import React from 'react'
import themes from '../assets/themes/themes';

const Themes = ({setSeeTheme, setTheme}) => {
  return (
    <div className='fixed top-0 left-0 z-10 w-full h-full bg-headingColor bg-opacity-40'>
      <div className="max-w-[600px] flex flex-col gap-4 justify-center absolute top-1/2 left-1/2 z-20 bg-gray-800 rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
        <p className="text-white">Select your theme:</p>
        <p className="text-red-600"></p>
        <button onClick={(e) => {setSeeTheme(false); }} className='absolute text-white top-3 right-5'><i className="ri-close-fill"></i></button>
        <div className='grid grid-cols-2 gap-10 sm:grid-cols-2 lg:grid-cols-3'>
          {
            themes.map((theme, i) => (<img src={theme.src} onClick={(e) => {setTheme(theme.name); window.localStorage.setItem('theme', theme.name)}}></img>))
          }
        </div>
      </div>
      
    </div>
  )
}

export default Themes
