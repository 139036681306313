  import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import { useLocation } from 'react-router-dom'
import { ResizableBox } from 'react-resizable';
import Final from '../components/Final';
import ResolutionModal from '../components/ResolutionModal';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../config/Config';
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-html"
import "ace-builds/src-min-noconflict/theme-dracula"
import "ace-builds/src-min-noconflict/mode-css"
import "ace-builds/src-min-noconflict/mode-javascript"
import "ace-builds/src-min-noconflict/theme-clouds"
import "ace-builds/src-min-noconflict/theme-chrome"
import "ace-builds/src-min-noconflict/theme-ambiance"
import "ace-builds/src-min-noconflict/theme-cobalt"
import Themes from '../components/Themes';
import Image from '../components/Image';
import ImgModal from '../components/ImgModal';
const Demo = () => {
  const location = useLocation();
  const [position, setPosition] = useState('relative');
  const [width, setWidth] = useState('2/3')
  const [isfullScreen, setFullScreen] = useState(false);
  const [height, setHeight] = useState('calc(100vh - 60px)');
  const [seeResModal, setSeeResModal] = useState(false);
  const [seeHeader, setSeeHeader] = useState(true);
  const [content, setContent] = useState(' ');
  const [styles, setStyles] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: position,
    width: width,
    height: height,
  })
  const [html, setHtml] = useState('');
  const [css, setCss] = useState('');
  const [js, setJs] = useState('');
  const [input, setInput] = useState('html');
  const [scris, setScris] = useState('');
  const iframeRef = useRef(null)
  const [codePlaces, setCodePlaces] = useState([]);
  const [language, setLanguage] = useState('html');
  const editorRef = useRef(null);
  const getTheme = () => {
    const datas = window.localStorage.getItem('theme');
    if (datas !== null){
      return JSON.parse(datas);
    }else{
      return 'dracula';
    }
  }

  const [themes, setTheme] = useState('dracula');
  const [themeModal, setThemeModal] = useState(false);
  

  useEffect(() => {
    if (window.localStorage.getItem('theme') !== null){
      setTheme(window.localStorage.getItem('theme'));
    }
  }, [])

  const set = (text) => {
    if (input === 'html'){
      setHtml(text);
    }else if (input === 'js'){
      setJs(text);
    }else if (input === 'css'){
      setCss(text);
    }
  }

  useEffect(() => {
    const iframe = document.getElementById('iframe_id');
    const iframeDocument = iframe.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(`<html><head><style>${css}</style></head><body>${html}<script>${js}</script></body></html>`);
    iframeDocument.close();
  }, [html, css, js])
  

  const fullScreen = (e) => {
    e.preventDefault()
    if (!isfullScreen){
      setPosition('fixed');
      setWidth('100%');
      setHeight('100vh')
      setFullScreen(true);
      setSeeHeader(false);
    }else{
      setWidth('2/3');
      setPosition('relative')
      setFullScreen(false)
      setHeight('calc(100vh - 60px)')
      setSeeHeader(true);
    }
  }
  useEffect(() => {
    setStyles({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: position,
      width: width,
      height: height
    })
    if (js === undefined){
      setJs(' ')
    }
    if (css === undefined){
      setCss('body{background-color: red;}')
    }if (html === undefined){
      setHtml(' ')
    }
  }, [width, height]);

  const reload = () => {
    iframeRef.current.src = "";
  }

  const update = async () => {
    const userRef = doc(db, 'users', location.state.id);
    await updateDoc(userRef, {
      codeplaces: codePlaces,
    });
  }
  useEffect(() => {
    const iframe = document.getElementById('iframe_id');
    const iframeDocument = iframe.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write('<p class="sal">Hello, World! Make any modification to reset this.</p>');
    iframeDocument.close();
    
  }, []);
  const change = (item) => {
    if (item === 'html'){
      document.getElementById('htmlButton').style.borderBottom = "solid 5px";
      document.getElementById('htmlButton').style.borderBottomColor = "white";
      document.getElementById('cssButton').style.borderBottom = "none";
      document.getElementById('jsButton').style.borderBottom = "none";
    }else if (item === 'css'){
      document.getElementById('cssButton').style.borderBottom = "solid 5px";
      document.getElementById('cssButton').style.borderBottomColor = "white";
      document.getElementById('htmlButton').style.borderBottom = "none";
      document.getElementById('jsButton').style.borderBottom = "none";
    }else if (item === 'js'){
      document.getElementById('jsButton').style.borderBottom = "solid 5px";
      document.getElementById('jsButton').style.borderBottomColor = "white";
      document.getElementById('cssButton').style.borderBottom = "none";
      document.getElementById('htmlButton').style.borderBottom = "none";
    }
  }


  return (
    <div>
      {seeHeader && <Header />}
      <div className='flex flex-col sm:flex-row calc'>
        {seeHeader && 
        <div className='bg-gray-800 calc w-full h-[300px] sm:w-1/3 flex flex-col'>
          <div className='bg-gray-500 w-full h-[50px] grid grid-cols-3'>
            <div onClick={() => {setLanguage('html'); setInput('html'); setScris(html); change('html')}} id="htmlButton" className='bg-gray-700 border-solid border-b-[5px] border-gray-900 flex justify-center hover:bg-gray-600 cursor-pointer items-center text-white'><i class="ri-html5-fill"></i> - Html</div>
            <div onClick={() => {setLanguage('css'); setInput('css'); setScris(css); change('css')}} id='cssButton' className='flex items-center justify-center text-white bg-gray-700 cursor-pointer hover:bg-gray-600'><i class="ri-css3-fill"></i> - Css</div>
            <div onClick={() => {setLanguage('javascript'); setInput('js'); setScris(js); change('js')}} id='jsButton' className='flex items-center justify-center text-white bg-gray-700 cursor-pointer hover:bg-gray-600'><i class="ri-javascript-line"> - Js</i></div>
          </div>
          {/* <textarea id="textbox" ref={textareaRef} onKeyDown={(e) => {handleKeyDown(e)}} value={scris} onChange={(e) => {set(e.target.value); setScris(e.target.value)}} name="html" cols="30" rows="20" className='w-full h-full overflow-auto text-white bg-gray-700 resize-none'>{content}</textarea> */}
          <AceEditor
    mode={language}
    theme={themes}
    onChange={(e) => {set(editorRef.current.editor.getValue()); setScris(editorRef.current.editor.getValue())}}
    name="UNIQUE_ID_OF_DIV"
    showGutter={true}
    highlightActiveLine={true}
    editorProps={{ $blockScrolling: true }}
    style={{width: "100%", height: "calc(100vh - 100px)"}}
    value={scris}
    fontSize={'16px'}
  
    ref={editorRef}
  />
        </div>}
        <div className='w-full group sm:w-2/3' >
          <div className='relative calc' style={styles}>
            <Final iframeRef={iframeRef} fullScreen={fullScreen}/>
          </div>
          <div className='absolute top-20 right-5 w-[125px] h-[30px] hidden group-hover:grid grid-cols-2 rounded-[6px] bg-gray-700'>
            <div onClick={(e) => fullScreen(e)} className='flex items-center justify-center h-full cursor-pointer hover:bg-gray-500'><i class="ri-fullscreen-line"></i></div>
            <div onClick={(e) => alert('You cannot access settings in demo.')} className='flex items-center justify-center h-full cursor-pointer hover:bg-gray-500'><i class="ri-settings-3-line"></i></div>
            {/* <div onClick={(e) => save()} className='flex items-center justify-center bg-green-400 cursor-pointer hover:bg-green-300 color-white'><i class="ri-save-line"></i></div> */}
          </div>
        </div>
    </div>
  </div>

  );
}


export default Demo
