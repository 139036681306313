import React from 'react'

const AboutMe = () => {
  return (
    <div id='about' className='flex flex-col items-center justify-center h-[100vh]'>
      <p className='text-white text-[40px] sm:text-[60px] font-[Pacifico]'>About Developer</p>
      <ul className='flex justify-center items-center flex-col gap-3 my-3 text-[10px] sm:text-[15px] lg:text-[35px] list-disc text-white font-[Exo]'>
        <li>Software Developer</li>
        <li>You can contact him through discord: xSenny_#6531</li>
        <li>Visit his <a href="https://github.com/xSenny"><i class="ri-github-fill"></i></a></li>
      </ul>
    </div>
  )
}

export default AboutMe
