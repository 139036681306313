import React from 'react'
import LandingHeader from '../components/LandingHeader'
import Home from '../components/Home'
import Features from '../components/Features'
import AboutMe from '../components/AboutMe'
import SupportMe from '../components/SupportMe'

const Landing = () => {
  return (
    <div className='bg-[#3F0D71]'>
      <LandingHeader />
      <Home />
      <Features />
      <AboutMe />
      <SupportMe />
    </div>
  )
}

export default Landing
